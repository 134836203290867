import React, { useState, useEffect, useRef } from 'react';
import { Box, VStack, Heading, Text, Button, FormControl, FormLabel, Input, SimpleGrid, useToast } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useCart } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { createOrder, createPaymentIntent } from '../utils/api';

// Define the shape of the form data
interface FormData {
    name: string;
    email: string;
    address: string;
    city: string;
    country: string;
    region: string;
    postalCode: string;
}

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    address: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    country: yup.string().required('Country is required'),
    region: yup.string().required('State/Province is required'),
    postalCode: yup.string().required('Postal code is required'),
});

const Checkout: React.FC = () => {
    const { items, total, clearCart } = useCart();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const toast = useToast();

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [checkoutError, setCheckoutError] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');

    const paymentIntentCreated = useRef<boolean>(false);
    const paymentIntentTimer = useRef<number | null>(null);

    const { control, handleSubmit, formState: { errors } } = useForm<FormData>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        console.log('Checkout component rendered. Total:', total);

        const fetchPaymentIntent = async () => {
            if (total > 0 && !paymentIntentCreated.current) {
                console.log('Fetching payment intent. Total:', total);
                try {
                    const response = await createPaymentIntent(Math.round(total * 100), 'usd');
                    console.log('Payment intent created successfully');
                    setClientSecret(response.data.clientSecret);
                    paymentIntentCreated.current = true;
                } catch (error) {
                    console.error('Failed to create payment intent:', error);
                    setCheckoutError('Failed to initialize checkout. Please try again.');
                }
            } else {
                console.log('Skipping payment intent creation. Total:', total, 'Already created:', paymentIntentCreated.current);
            }
        };

        // Clear any existing timer
        if (paymentIntentTimer.current !== null) {
            clearTimeout(paymentIntentTimer.current);
        }

        // Set a new timer to delay the API call
        paymentIntentTimer.current = window.setTimeout(() => {
            fetchPaymentIntent();
        }, 100);  // 100ms delay

        return () => {
            // Clean up function
            if (paymentIntentTimer.current !== null) {
                clearTimeout(paymentIntentTimer.current);
            }
        };
    }, [total]);

    const onSubmit = async (data: FormData) => {
        if (!stripe || !elements || !clientSecret) {
            return;
        }

        setIsProcessing(true);

        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
            setCheckoutError('An error occurred. Please try again.');
            setIsProcessing(false);
            return;
        }

        try {
            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: data.name,
                        email: data.email,
                        address: {
                            line1: data.address,
                            city: data.city,
                            state: data.region,
                            postal_code: data.postalCode,
                            country: data.country,
                        },
                    },
                },
            });

            if (error) {
                setCheckoutError(error.message || 'An error occurred. Please try again.');
                setIsProcessing(false);
                return;
            }

            if (paymentIntent.status === 'succeeded') {
                const orderItems = items.map(item => (Number(item.id)));
                const customerData = {
                    name: data.name,
                    email: data.email,
                    address: data.address,
                    city: data.city,
                    country: data.country,
                    region: data.region,
                    postal_code: data.postalCode,
                };

                const response = await createOrder(
                    orderItems,
                    total,
                    customerData,
                );

                if (response.data.order) {
                    const orderDetails = {
                        items: items,
                        total: total,
                        orderId: response.data.order.id // Assuming the API returns an order ID
                    };
                    clearCart();
                    toast({
                        title: "Order placed successfully",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                    navigate('/order-success', { state: orderDetails });
                } else {
                    throw new Error('Failed to create order');
                }
            }
        } catch (err) {
            setCheckoutError('An error occurred while processing your order. Please try again.');
        }

        setIsProcessing(false);
    };

    return (
        <Box maxWidth="800px" margin="auto" mt={8} p={4}>
            <Heading as="h1" size="xl" mb={6}>Checkout</Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={4} align="stretch">
                    <SimpleGrid columns={2} spacing={4}>
                        <FormControl isInvalid={!!errors.name}>
                            <FormLabel>Full Name</FormLabel>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                            <Text color="red.500">{errors.name?.message}</Text>
                        </FormControl>
                        <FormControl isInvalid={!!errors.email}>
                            <FormLabel>Email</FormLabel>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                            <Text color="red.500">{errors.email?.message}</Text>
                        </FormControl>
                    </SimpleGrid>
                    <FormControl isInvalid={!!errors.address}>
                        <FormLabel>Address</FormLabel>
                        <Controller
                            name="address"
                            control={control}
                            render={({ field }) => <Input {...field} />}
                        />
                        <Text color="red.500">{errors.address?.message}</Text>
                    </FormControl>
                    <SimpleGrid columns={2} spacing={4}>
                        <FormControl isInvalid={!!errors.city}>
                            <FormLabel>City</FormLabel>
                            <Controller
                                name="city"
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                            <Text color="red.500">{errors.city?.message}</Text>
                        </FormControl>
                        <FormControl isInvalid={!!errors.postalCode}>
                            <FormLabel>Postal Code</FormLabel>
                            <Controller
                                name="postalCode"
                                control={control}
                                render={({ field }) => <Input {...field} />}
                            />
                            <Text color="red.500">{errors.postalCode?.message}</Text>
                        </FormControl>
                    </SimpleGrid>
                    <SimpleGrid columns={2} spacing={4}>
                        <FormControl isInvalid={!!errors.country}>
                            <FormLabel>Country</FormLabel>
                            <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                    <CountryDropdown
                                        valueType="short"
                                        value={field.value}
                                        onChange={(val) => field.onChange(val)}
                                    />
                                )}
                            />
                            <Text color="red.500">{errors.country?.message}</Text>
                        </FormControl>
                        <FormControl isInvalid={!!errors.region}>
                            <FormLabel>State/Province Code</FormLabel>
                            <Controller
                                name="region"
                                control={control}
                                rules={{
                                    required: "State/Province code is required",
                                    pattern: {
                                        value: /^[A-Z]{2}$/,
                                        message: "Please enter a valid 2-letter state/province code"
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        placeholder="Enter 2-letter state/province code"
                                        maxLength={2}
                                        onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                                    />
                                )}
                            />
                            <Text color="red.500">{errors.region?.message}</Text>
                        </FormControl>
                    </SimpleGrid>
                    <FormControl>
                        <FormLabel>Card Details</FormLabel>
                        <Box border="1px solid" borderColor="gray.200" borderRadius="md" p={2}>
                            <CardElement />
                        </Box>
                    </FormControl>
                    <Text fontWeight="bold">Total: ${total.toFixed(2)}</Text>
                    {checkoutError && <Text color="red.500">{checkoutError}</Text>}
                    <Button type="submit" colorScheme="brand" isLoading={isProcessing} loadingText="Processing" isDisabled={!clientSecret}>
                        Pay Now
                    </Button>
                </VStack>
            </form>
        </Box>
    );
};

export default Checkout;