// src/App.tsx
import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import GeneratedImages from './pages/GeneratedImages';
import Register from "./components/Register";
import Login from "./components/Login";
import Orders from "./components/Orders";
import {AuthContext, AuthProvider} from "./contexts/AuthContext";
import {CartProvider} from "./contexts/CartContext";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import ProtectedRoute from "./components/ProtectedRoutes";
import theme from "./theme";
import OrderSuccess from "./pages/OrderSuccess";


// Make sure to replace 'your_stripe_publishable_key' with your actual Stripe publishable key
const stripePromise = loadStripe('pk_test_51OCYwiK8zNrPARWCwo8BHUAimaoBT0HkoH0itMrAhNyUZY1y7LGeSSTJ3NydAoojpnX2AFy9qwAXvdKF5AfLkikU00huqScso2');


const App: React.FC = () => {
  return (
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <CartProvider>
            <Elements stripe={stripePromise}>
              <Router>
                <Navbar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/generated-images" element={<GeneratedImages />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/orders" element={
                    <ProtectedRoute>
                      <Orders />
                    </ProtectedRoute>
                  } />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/order-success" element={<OrderSuccess />} /> {/* Add this line */}
                </Routes>
              </Router>
            </Elements>
          </CartProvider>
        </AuthProvider>
      </ChakraProvider>
  );
}

export default App;